import { EMPTY_COUNTRY } from "./commons";
import { Country } from "./commons";

export const NUM_CHILDREN = 4;

export const EMPTY_CHILD = {
  name: "",
  surname: "",
  birthday: "",
  livesHome: true,
};

export const EMPTY_PARTNER = {
  name: "",
  surname: "",
  birthday: "",
  nationality: { ...EMPTY_COUNTRY },
  livesHome: true,
};

export const EMPTY_PARTNER_ERROR = {
  name: "",
  surname: "",
  birthday: "",
  nationality: "",
  livesHome: "",
};

export const EMPTY_PARENT = {
  name: "",
  surname: "",
  birthday: "",
  birthPlace: "",
  birthCountry: { ...EMPTY_COUNTRY },
  nationality: { ...EMPTY_COUNTRY },
};

export const EMPTY_PARENT_ERRROR = {
  name: "",
  surname: "",
  birthday: "",
  birthPlace: "",
  birthCountry: "",
  nationality: "",
};

export const EMPTY_MIN_PERSON = {
  name: "",
  surname: "",
  birthday: "",
};

export interface Child {
  name: string;
  surname: string;
  birthday: string;
  livesHome: boolean;
}

export interface Person {
  name: string;
  surname: string;
  birthday: string;
  livesHome?: boolean;
  travelsWith?: boolean;
  nationality?: Country;
  birthPlace?: string;
  birthCountry?: Country;
}

export interface PersonError {
  name?: string;
  surname?: string;
  birthday?: string;
  livesHome?: string;
  travelsWith?: string;
  nationality?: string;
  birthPlace?: string;
  birthCountry?: string;
}
