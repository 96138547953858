import { Applicant, ApplicantErrors, ValidationResult } from "../../types/applicants";
import { EMPTY_PARENT_ERRROR, EMPTY_PARTNER_ERROR } from "../../types/persons";
import { EMPTY_IMMERGENCY_CONTACT, NUM_RELATIVES } from "../../types/relatives";
import { EMPTY_PASSPORT_ERRORS } from "../../types/travels";
import { isCountryValid } from "../../utils/applicant-utils";
import {
  containsNonLatinCharacters,
  isCityNameValid,
  isDateEarlier,
  isDateInTheFuture,
  isDateInThePast,
  isNameValid,
  isPhoneValid,
} from "../../utils/utils";

export function validateApplicant(applicant: Applicant): ValidationResult {
  let newErrors: ApplicantErrors = { name: "", surname: "" };
  let formIsValid = true;

  if (applicant) {
    // ----------------- Geneal Information --------------------
    if (!isNameValid(applicant.name)) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isNameValid(applicant.surname)) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters!";
      formIsValid = false;
    }

    if (!applicant.nationality) {
      newErrors.nationality = "Nationality is required!";
      formIsValid = false;
    }

    if (!applicant.gender) {
      newErrors.gender = "Please, pick your gender!";
      formIsValid = false;
    }

    if (!applicant.birth_date || !isDateInThePast(applicant.birth_date, 17)) {
      newErrors.birth_date = "Birth date is required and must be at least 17 years in the past!";
      formIsValid = false;
    }

    if (!isCityNameValid(applicant.birth_place)) {
      newErrors.birth_place =
        "The place of birth is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isCountryValid(applicant.birth_country)) {
      newErrors.birth_country = "The country of birth is required";
      formIsValid = false;
    }

    if (!newErrors.passport) {
      newErrors.passport = { ...EMPTY_PASSPORT_ERRORS };
    }

    if (
      !applicant.passport ||
      !applicant.passport.id ||
      !/^[A-Za-z0-9]+$/.test(applicant.passport.id)
    ) {
      newErrors.passport.id =
        "Passport ID is required and should only contain numbers alphabetic characters";
      formIsValid = false;
    }

    if (!applicant.passport || !isCountryValid(applicant.passport.issuing_country)) {
      newErrors.passport.issuing_country = "Passport issuing country is required";
      formIsValid = false;
    }

    if (!applicant.passport || !isCityNameValid(applicant.passport.issuing_place)) {
      newErrors.passport.issuing_place =
        "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (
      !applicant.passport ||
      !applicant.passport.issue_date ||
      !isDateInThePast(applicant.passport.issue_date)
    ) {
      newErrors.passport.issue_date =
        "Issue Date of your passport is required and must be in the past";
      formIsValid = false;
    }

    if (
      !applicant.passport ||
      !applicant.passport.expiry_date ||
      !isDateInTheFuture(applicant.passport.expiry_date)
    ) {
      newErrors.passport.expiry_date =
        "Expiry Date of your passport is required and must be in the future";
      formIsValid = false;
    }

    if (!applicant.passport || !applicant.passport.is_biometric) {
      newErrors.passport.is_biometric = "Please, choose which type of passport you have!";
      formIsValid = false;
    }

    if (!applicant.residency_street) {
      newErrors.residency_street = "Street address is required!";
      formIsValid = false;
    }

    if (!applicant.residency_zip) {
      newErrors.residency_zip = "ZIP code is required!";
      formIsValid = false;
    }

    if (!isCityNameValid(applicant.residency_city)) {
      newErrors.residency_city = "City is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (applicant.residency_province && !isCityNameValid(applicant.residency_province)) {
      newErrors.residency_province = "The province name should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isCountryValid(applicant.residency_country)) {
      newErrors.residency_country = "Country of residence is required";
      formIsValid = false;
      console.log("Country of residence is false");
    }

    if (!applicant.residency_date_since || !isDateInThePast(applicant.residency_date_since)) {
      newErrors.residency_date_since = "Date of residency is required and must be in the past";
      formIsValid = false;
    }

    if (!applicant.residency_ownership) {
      newErrors.residency_ownership = "Please, pick the proper ownership status for this property!";
      formIsValid = false;
    }

    if (applicant.residency_ownership === "other" && !applicant.residency_ownership_other) {
      newErrors.residency_ownership_other = "Please, specify the ownership status!";
      formIsValid = false;
    }

    if (
      applicant.residency_ownership === "other" &&
      containsNonLatinCharacters(applicant.residency_ownership_other)
    ) {
      newErrors.residency_ownership_other =
        "Please, specify the ownership status using only latin letters!";
      formIsValid = false;
    }

    if (!isPhoneValid(applicant.phone)) {
      newErrors.phone = "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }
  }
  return { isValid: formIsValid, errors: newErrors };
}

export function validateFamily(applicant: Applicant): ValidationResult {
  let newErrors: ApplicantErrors = { name: "", surname: "" };
  let formIsValid = true;

  if (applicant) {
    // ----------------- Family Information --------------------
    if (!applicant.marital_status) {
      newErrors.marital_status = "Marital status is required!";
      formIsValid = false;
    }

    console.log("has children: " + applicant.has_children);
    console.log("children: ");
    console.log(applicant.children);

    if (applicant.has_children === undefined) {
      newErrors.has_children = "Please, choose wether you have children!";
      formIsValid = false;
    }

    if (applicant.has_children && (!applicant.children || applicant.children.length === 0)) {
      newErrors.has_children = "At least one child is required!";
      formIsValid = false;
    }

    if (applicant.has_children && applicant.children) {
      newErrors.children = Array.from({ length: applicant.children.length }, () => ({
        name: "",
        surname: "",
        birthday: "",
        livesHome: true,
      }));
      console.log("children");
      console.log(applicant.children);
      for (let i = 0; i < applicant.children.length; i++) {
        console.log("child: " + i);
        const child = applicant.children[i];
        if (child.name && child.surname && !child.birthday) {
          console.log("cond 1: true");
          newErrors.children[i].birthday = "The birth date of the child is required!";
          formIsValid = false;
        } else if (!child.name && child.surname && child.birthday) {
          console.log("cond 2: true");
          newErrors.children[i].name =
            "Name is required and should only contain alphabetic characters!";
          formIsValid = false;
        } else if (child.name && !child.surname && child.birthday) {
          console.log("cond 3: true");
          newErrors.children[i].surname = "The surname of the child is required!";
          formIsValid = false;
        }

        if (child.name && !isNameValid(child.name)) {
          console.log("invalid name");
          newErrors.children[i].name =
            "Name is required and should only contain alphabetic characters!";
          formIsValid = false;
        }
        if (child.surname && !isNameValid(child.surname)) {
          console.log("invalid surname");
          newErrors.children[i].surname =
            "Name is required and should only contain alphabetic characters!";
          formIsValid = false;
        }
      }
      console.log(newErrors.children);
    }
    // --------- partner -------------
    if (applicant.marital_status === "Married") {
      if (!newErrors.partner) {
        newErrors.partner = { ...EMPTY_PARTNER_ERROR };
      }
      if (!isNameValid(applicant.partner?.name)) {
        newErrors.partner.name =
          "Name of the partner is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isNameValid(applicant.partner?.surname)) {
        newErrors.partner.surname =
          "Surname of the partner is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isCountryValid(applicant.partner?.nationality)) {
        newErrors.partner.nationality = "Nationality is required!";
        formIsValid = false;
      }

      if (!(applicant.partner?.birthday && isDateInThePast(applicant.partner.birthday))) {
        newErrors.partner.birthday = "Birth Date is required and must be in the past!";
        formIsValid = false;
      }

      if (applicant.partner?.livesHome === undefined) {
        newErrors.partner.livesHome = "Please, select the right option!";
        formIsValid = false;
      }
    }
    if (!applicant.parents_unknown) {
      // --------- mother -------------
      if (!newErrors.mother) {
        newErrors.mother = { ...EMPTY_PARENT_ERRROR };
      }
      if (!applicant.mother || !isNameValid(applicant.mother.name)) {
        newErrors.mother.name =
          "Name of the mother is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isNameValid(applicant.mother?.surname)) {
        newErrors.mother.surname =
          "Surname of the mother is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isCountryValid(applicant.mother?.nationality)) {
        newErrors.mother.nationality = "Nationality is required!";
        formIsValid = false;
      }

      if (!(applicant.mother && isDateInThePast(applicant.mother.birthday))) {
        newErrors.mother.birthday = "Birth Date is required and must be in the past!";
        formIsValid = false;
      }

      if (!isCityNameValid(applicant.mother?.birthPlace)) {
        newErrors.mother.birthPlace =
          "Mother's place of birth is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isCountryValid(applicant.mother?.birthCountry)) {
        newErrors.mother.birthCountry = "Mother's country of birth is required!";
        formIsValid = false;
      }

      // --------- father -------------
      if (!newErrors.father) {
        newErrors.father = { ...EMPTY_PARENT_ERRROR };
      }

      if (!applicant.father || !isNameValid(applicant.father.name)) {
        newErrors.father.name =
          "Name of the father is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isNameValid(applicant.father?.surname)) {
        newErrors.father.surname =
          "Surname of the father is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isCountryValid(applicant.father?.nationality)) {
        newErrors.father.nationality = "Nationality is required!";
        formIsValid = false;
      }

      if (!applicant.father?.birthday || !isDateInThePast(applicant.father.birthday)) {
        newErrors.father.birthday = "Birth Date is required and must be in the past!";
        formIsValid = false;
      }

      if (!isCityNameValid(applicant.father?.birthPlace)) {
        newErrors.father.birthPlace =
          "Father's place of birth is required and should only contain alphabetic characters!";
        formIsValid = false;
      }

      if (!isCountryValid(applicant.father?.birthCountry)) {
        newErrors.father.birthCountry = "Father's country of birth is required!";
        formIsValid = false;
      }
    }

    // --------- Immergency Contact -------------
    if (!applicant.immergency_contact || !isNameValid(applicant.immergency_contact.name)) {
      if (!newErrors.immergency_contact) {
        newErrors.immergency_contact = EMPTY_IMMERGENCY_CONTACT;
      }
      newErrors.immergency_contact.name =
        "Name of the immergency contact is required and should only contain alphabetic characters!";
      formIsValid = false;
    }

    if (!applicant.immergency_contact || !isPhoneValid(applicant.immergency_contact.phone)) {
      if (!newErrors.immergency_contact) {
        newErrors.immergency_contact = EMPTY_IMMERGENCY_CONTACT;
      }
      newErrors.immergency_contact.phone =
        "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }

    if (!applicant.immergency_contact || !applicant.immergency_contact.relation) {
      if (!newErrors.immergency_contact) {
        newErrors.immergency_contact = EMPTY_IMMERGENCY_CONTACT;
      }
      newErrors.immergency_contact.relation =
        "The relation to your immergency contact is required!";
      formIsValid = false;
    }

    // --------- Relatives in the UK -------------
    console.log("has relatives in the UK: " + applicant.has_relatives_in_UK);
    console.log("relatives: ");
    console.log(applicant.relatives_UK);

    if (applicant.has_relatives_in_UK === undefined) {
      newErrors.has_relatives_in_UK = "Please, choose wether you have relatives in the UK!";
      formIsValid = false;
    }

    if (
      applicant.has_relatives_in_UK &&
      (!applicant.relatives_UK || applicant.relatives_UK.length === 0)
    ) {
      newErrors.has_relatives_in_UK = "At least one relative is required!";
      formIsValid = false;
    }

    if (applicant.has_relatives_in_UK && applicant.relatives_UK) {
      newErrors.relatives_UK = Array.from({ length: applicant.relatives_UK.length }, () => ({
        name: "",
        surname: "",
        nationality: "",
        relation: "",
        typeOfStay: "",
      }));
      console.log("relatives");
      console.log(applicant.relatives_UK);

      let emptyRelatives = 0;

      for (let i = 0; i < applicant.relatives_UK.length; i++) {
        console.log("relative: " + i);
        const relative = applicant.relatives_UK[i];

        if (
          relative.name ||
          relative.surname ||
          (relative.nationality && relative.nationality.code) ||
          relative.relation ||
          relative.typeOfStay
        ) {
          if (!(relative.nationality && relative.nationality.code)) {
            console.log("cond 1: true");
            newErrors.relatives_UK[i].nationality = "The nationality of the relative is required!";
            formIsValid = false;
          }
          if (!relative.name || !isNameValid(relative.name)) {
            console.log("cond 2: true");
            newErrors.relatives_UK[i].name =
              "Name is required and should only contain alphabetic characters!";
            formIsValid = false;
          }
          if (!relative.surname || !isNameValid(relative.surname)) {
            console.log("cond 3: true");
            newErrors.relatives_UK[i].surname =
              "Surname is required and should only contain alphabetic characters!";
            formIsValid = false;
          }
          if (!relative.relation) {
            console.log("cond 4: true");
            newErrors.relatives_UK[i].relation = "Pick the relation to your relative!";
            formIsValid = false;
          }
          if (!relative.typeOfStay) {
            console.log("cond 5: true");
            newErrors.relatives_UK[i].typeOfStay =
              "Pick on what terms your relative stays in the UK!";
            formIsValid = false;
          }
        } else {
          emptyRelatives++;
        }

        if (emptyRelatives === NUM_RELATIVES) {
          newErrors.has_relatives_in_UK =
            "At least one relative should be entered, or select, that you have no relatives!";
          formIsValid = false;
        }
      }
      console.log(newErrors.children);
    }
  }
  return { isValid: formIsValid, errors: newErrors };
}

export function validateTravel(applicant: Applicant): ValidationResult {
  let newErrors: ApplicantErrors = { name: "", surname: "" };
  let formIsValid = true;

  console.log("validating the travel information");

  if (applicant) {
    // ----------------- Travel Information --------------------
    if (applicant.deported_abroad) {
      console.log("Checking deported_abroad_details");
      if (!(applicant.deported_abroad_details && applicant.deported_abroad_details.length > 5)) {
        newErrors.deported_abroad_details =
          "If you answer yes to the previous question, you need to provide details!";
        formIsValid = false;
      }
    }

    if (applicant.UK_insurance) {
      if (!applicant.UK_insurance_number) {
        newErrors.UK_insurance_number = "Please, enter your National Insurance number!";
        formIsValid = false;
      }
    }

    // ------ travel to UK -----------
    if (applicant.has_travelled_to_UK) {
      if (applicant.travel_details_UK) {
        newErrors.travel_details_UK = Array.from(
          { length: applicant.travel_details_UK.length },
          () => ({ destination: "", from: "", to: "", reason: "" })
        );
        console.log("UK travel details");
        console.log(newErrors.travel_details_UK);

        let emptyTravels = 0;
        for (let i = 0; i < applicant.travel_details_UK.length; i++) {
          console.log("detail: " + i);
          const travel = applicant.travel_details_UK[i];
          if (travel.from && !travel.to) {
            console.log("cond 1: true");
            newErrors.travel_details_UK[i].to = "The end date of your stay in UK is required!";
            formIsValid = false;
          } else if (!travel.from && travel.to) {
            console.log("cond 2: true");
            newErrors.travel_details_UK[i].from =
              "The starting date of your stay in UK is required!";
            formIsValid = false;
          } else if (travel.from && travel.to && !travel.reason) {
            console.log("cond 3: true");
            newErrors.travel_details_UK[i].reason = "The reason for your stay in UK is required!";
            formIsValid = false;
          } else if (!travel.from && !travel.to && !travel.reason) {
            emptyTravels++;
          }
        }
        if (emptyTravels === applicant.travel_details_UK.length) {
          newErrors.has_travelled_to_UK = "At least one travel should be entered!";
          formIsValid = false;
        }
        console.log(newErrors.travel_details_UK);
      } else {
        console.log("empty UK travels");
        newErrors.has_travelled_to_UK = "At least one travel should be entered!";
        formIsValid = false;
      }
    }

    // ------ travel abroad -----------
    console.log("hast travelled abroad: " + applicant.has_travelled_abroad);
    console.log(applicant.travel_details_abroad);

    if (applicant.has_travelled_abroad) {
      if (applicant.travel_details_abroad) {
        newErrors.travel_details_abroad = Array.from(
          { length: applicant.travel_details_abroad.length },
          () => ({ destination: "", from: "", to: "", reason: "" })
        );
        console.log("travel abroad details");
        console.log(newErrors.travel_details_abroad);
        let emptyTravels = 0;
        for (let i = 0; i < applicant.travel_details_abroad.length; i++) {
          console.log("detail: " + i);
          const travel = applicant.travel_details_abroad[i];
          //
          if (
            !(travel.destination && travel.destination.code) &&
            !travel.from &&
            !travel.to &&
            !travel.reason
          ) {
            console.log("adding an empty travel");
            emptyTravels++;
          }
          //
          else {
            if (!(travel.destination && travel.destination.code)) {
              newErrors.travel_details_abroad[i].destination =
                "The country of your stay abroad is required!";
              formIsValid = false;
            }
            //UK is not allowed
            if (travel.destination && travel.destination.code === "GB") {
              newErrors.travel_details_abroad[i].destination =
                "This section is for travels outside of the UK! Use the section above for travels to the UK!";
              formIsValid = false;
            }

            //
            if (!travel.reason) {
              newErrors.travel_details_abroad[i].reason =
                "The reason for your stay abroad is required!";
              formIsValid = false;
            }
            //

            if (travel.destination && travel.destination.code && !travel.from && !travel.to) {
              console.log("cond 0: true");
              newErrors.travel_details_abroad[i].to = "The end date of your stay is required!";
              newErrors.travel_details_abroad[i].from =
                "The starting date of your stay abroad is required!";
              formIsValid = false;
            }
            //
            else if (travel.from && !travel.to) {
              console.log("cond 1: true");
              newErrors.travel_details_abroad[i].to = "The end date of your stay is required!";
              if (!(travel.destination && travel.destination.code)) {
                newErrors.travel_details_abroad[i].destination =
                  "The country of your stay abroad is required!";
              }
              formIsValid = false;
            }
            //
            else if (!travel.from && travel.to) {
              console.log("cond 2: true");
              newErrors.travel_details_abroad[i].from =
                "The starting date of your stay abroad is required!";
              formIsValid = false;
            }
            //
            else if (travel.from && travel.to) {
              console.log("cond 3: true");

              if (!isDateEarlier(travel.from, travel.to)) {
                newErrors.travel_details_abroad[i].from =
                  "The starting date of your stay must be before the return date!";
                formIsValid = false;
              } else if (!isDateInThePast(travel.from)) {
                newErrors.travel_details_abroad[i].from =
                  "The starting date of your stay must be in the past!";
                formIsValid = false;
              } else if (!isDateInThePast(travel.to)) {
                newErrors.travel_details_abroad[i].to =
                  "The ending date of your stay must be in the past!";
                formIsValid = false;
              } else if (isDateInThePast(travel.to, 10)) {
                newErrors.travel_details_abroad[i].to =
                  "The ending date of your stay must not older be than 10 years in the past!";
                formIsValid = false;
              }
            }
          }
        }
        console.log("empty travels: " + emptyTravels);
        if (emptyTravels === applicant.travel_details_abroad.length) {
          newErrors.has_travelled_abroad = "At least one travel should be entered!";
          formIsValid = false;
        }
        console.log(newErrors.travel_details_abroad);
      } else {
        console.log("empty travels");
        newErrors.has_travelled_abroad = "At least one travel should be entered!";
        formIsValid = false;
      }
    }
  }
  return { isValid: formIsValid, errors: newErrors };
}

export function validateAdditionalInformation(applicant: Applicant): ValidationResult {
  let newErrors: ApplicantErrors = { name: "", surname: "" };
  let formIsValid = true;

  console.log("validating the additional information");

  if (applicant) {
    if (applicant.has_driving_license === undefined) {
      newErrors.has_driving_license = "Please, select if you have a driving license!";
      formIsValid = false;
    }
    if (!applicant.english_level) {
      newErrors.english_level = "Please, pick your level of English!";
      formIsValid = false;
    }

    if (
      applicant.application_additional_information &&
      containsNonLatinCharacters(applicant.application_additional_information)
    ) {
      newErrors.application_additional_information = "Please, use only latin letters!";
      formIsValid = false;
    }

    if (applicant.work_experience && containsNonLatinCharacters(applicant.work_experience)) {
      newErrors.work_experience = "Please, use only latin letters!";
      formIsValid = false;
    }

    if (applicant.has_health_issues && containsNonLatinCharacters(applicant.has_health_issues)) {
      newErrors.has_health_issues = "Please, use only latin letters!";
      formIsValid = false;
    }
  }
  return { isValid: formIsValid, errors: newErrors };
}

export function validateFinalProvisions(applicant: Applicant): ValidationResult {
  let newErrors: ApplicantErrors = { name: "", surname: "" };
  let formIsValid = true;

  console.log("validating the final provisions");

  return { isValid: formIsValid, errors: newErrors };
}
