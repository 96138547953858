import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Question } from "../types/surveys";
import { CustomTextField } from "./custom-textfield";
import { FormattedMessage } from "react-intl";
import { RadioYesNo } from "./radio-button-yes-no";
import { SelectCountry } from "./select-country";

type QuestionComponentProps = {
  question: Question;
  value: any;
  translated: boolean;
  error: boolean;
  helperText?: string;
  onChange: (event: any, question: Question, value: any) => void;
} & TextFieldProps;

export const QuestionComponent: React.FC<QuestionComponentProps> = ({
  question,
  translated = true,
  value,
  onChange,
  error,
  helperText,
  ...restProps
}) => {
  const handleChangeMultiSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    const answer_id = event.target.name;
    const newValues = { ...value };
    newValues[answer_id] = newValue ? "true" : "false";
    onChange(event, question, newValues);
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    onChange(event, question, newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    onChange(event, question, newValue);
  };

  const getMultiSelectOptionChecked = (value: any, optionId: string) => {
    const result = (value && value[optionId] && value[optionId] === "true") || false;
    return result;
  };

  switch (question.type) {
    case "boolean":
      return (
        <FormControl error={error}>
          <FormControlLabel
            control={
              <Checkbox
                name={question.id}
                size="medium"
                checked={(value && value === "true") || false}
                onChange={(e) => handleChange(e, e.target.checked)}
              />
            }
            label={<FormattedMessage id={question.id} defaultMessage={question.label} />}
          />
          <FormHelperText>
            {error &&
              (translated ? (
                <FormattedMessage
                  id={question.id + "_error"}
                  defaultMessage={helperText || question.errorMessage || ""}
                />
              ) : (
                helperText
              ))}
          </FormHelperText>
        </FormControl>
      );
    case "radio_yes_no":
      return (
        <RadioYesNo
          key={question.id}
          id={question.id}
          error={!!error}
          helperText={helperText || question.errorMessage || ""}
          label={question.label}
          value={value && value === "true"}
          onChange={(e) => handleChange(e, e.target.value)}
          translated={translated}
          sx={restProps.sx}
        />
      );
    case "select":
      return (
        <CustomTextField
          id={question.id}
          name={question.id}
          error={!!error}
          helperText={helperText || question.errorMessage || ""}
          label={question.label}
          value={value || ""}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, e.target.value)}
          translated={translated}
          select
          fullWidth
          {...restProps}
        >
          {question.options &&
            question.options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {translated ? (
                  <FormattedMessage id={option.id} defaultMessage={option.label} />
                ) : (
                  option.label
                )}
              </MenuItem>
            ))}
        </CustomTextField>
      );
    case "select_multiple":
      return (
        <FormControl component="fieldset" variant="standard" error={error}>
          <FormLabel component="legend">
            {translated ? (
              <FormattedMessage id={question.id} defaultMessage={question.label} />
            ) : (
              question.label
            )}
          </FormLabel>
          <FormGroup>
            {question.options?.map((option) => (
              <FormControlLabel
                key={"f_" + option.id}
                control={
                  <Checkbox
                    key={"c_" + option.id}
                    checked={getMultiSelectOptionChecked(value, option.id)}
                    onChange={(e) => handleChangeMultiSelect(e, e.target.checked)}
                    name={option.id}
                  />
                }
                label={
                  translated ? (
                    <FormattedMessage id={option.id} defaultMessage={option.label} />
                  ) : (
                    option.label
                  )
                }
              />
            ))}
          </FormGroup>
          <FormHelperText>
            {error &&
              (translated ? (
                <FormattedMessage
                  id={question.id + "_error"}
                  defaultMessage={helperText || question.errorMessage || ""}
                />
              ) : (
                helperText
              ))}
          </FormHelperText>
        </FormControl>
      );

    case "toggle":
      return (
        <FormControl fullWidth error={error}>
          <FormLabel>
            {translated ? (
              <FormattedMessage id={question.id} defaultMessage={question.label} />
            ) : (
              question.label
            )}
          </FormLabel>
          <ToggleButtonGroup
            id={question.id}
            value={value || ""}
            exclusive
            onChange={handleToggleChange}
            sx={{
              marginTop: "8px",
              ".MuiToggleButton-root": {
                "&.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.contrastText,
                },
              },
            }}
          >
            {question.options &&
              question.options.map((option) => (
                <ToggleButton id={option.id} value={option.value} key={option.id}>
                  {translated ? (
                    <FormattedMessage id={option.id} defaultMessage={option.label} />
                  ) : (
                    option.label
                  )}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
          <FormHelperText>
            {error &&
              (translated ? (
                <FormattedMessage
                  id={question.id + "_error"}
                  defaultMessage={helperText || question.errorMessage || ""}
                />
              ) : (
                helperText
              ))}
          </FormHelperText>
        </FormControl>
      );
    case "placeholder":
      return <div>{question.label}</div>;

    case "country":
      return (
        <SelectCountry
          id={question.id}
          label={question.label}
          value={value || ""}
          error={error}
          helperText={helperText || question.errorMessage || ""}
          onChange={handleChange}
          translated={translated}
        />
      );
    default:
      return (
        <CustomTextField
          id={question.id}
          name={question.id}
          error={error}
          helperText={helperText || question.errorMessage || ""}
          label={question.label}
          multiline={question.type === "multiline"}
          rows={question.type === "multiline" ? 2 : undefined}
          value={value || ""}
          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>, e.target.value)}
          translated={translated}
          type={question.type}
          fullWidth
          {...restProps}
        />
      );
  }
};

export default QuestionComponent;

// | "radio"
