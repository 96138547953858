const en = {
  welcome_title: "Working in the United Kingdom with a visa",
  hint_text:
    "Why do we need your personal data and how are we going to use it? " +
    "By submitting this form you agree that IBC Student Exchange is an " +
    "administrator of personal data and as such will process it in accordance with GDPR " +
    "- EU General Data Protection Regulation 2016/679. " +
    "The collection of your personal data is necessary in connection with your application " +
    "for our programmes. Please read more about our Privacy Policy:",
  privacy_policy: "I agree to the Terms and Conditions of the Privacy Policy.",
};

export default en;
