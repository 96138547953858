export declare type FilterOp =
  | "<"
  | "<="
  | "=="
  | "!="
  | ">="
  | ">"
  | "array-contains"
  | "in"
  | "array-contains-any"
  | "not-in";

export type FilterValue = {
  value: unknown;
  operator?: FilterOp;
};

export type Filter = Record<string, FilterValue>;

export interface LogEntry {
  collection: string;
  documentId: string;
  sponsorId: string;
  operation: string;
  changedFields: any;
}

export interface Country {
  code: string;
  label: string;
  phone?: string;
  suggested?: boolean;
}
export const EMPTY_COUNTRY = {
  code: "",
  label: "",
};

export interface Region {
  code: string;
  label: string;
}

export const EMPTY_REGION = {
  code: "",
  label: "",
};

export interface Regions {
  country: Country;
  regions: Region[];
}

export interface Address {
  street: string;
  street2?: string;
  zip: string;
  city: string;
  region?: string;
  country: Country;
}

export interface AddressErrors {
  street?: string;
  street2?: string;
  zip?: string;
  city?: string;
  region?: string;
  country?: string;
}

export const EMPTY_ADDRESS: Address = {
  street: "",
  zip: "",
  city: "",
  country: { ...EMPTY_COUNTRY },
};

export const EMPTY_ADDRESS_ERRORS = {
  street: "",
  street2: "",
  zip: "",
  city: "",
  region: "",
  country: "",
};

export interface TrackedRecord {
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface TenantRecord {
  sponsorId: string;
  agentId?: string;
}
