const ro = {
  // ------------ General variables ------------
  radio_yes: "Da",
  radio_no: "Nu",
  radio_undefined: "Vă rugăm să selectați cel puțin o opțiune!",
  travel_reason_work: "Muncă",
  travel_reason_tourism: "Turism",
  travel_reason_study: "Studiu",
  travel_reason_transit: "Tranzit",
  // ------------ Relatives relationship ------------
  relative_reason_none: "selectați o opțiune",
  relative_reason_adoped_child: "Copil adoptat",
  relative_reason_brother: "Frate",
  relative_reason_brother_in_law: "CumătruCumnat",
  relative_reason_child: "Copil",
  relative_reason_child_spousce: "Noră / Ginere",
  relative_reason_daughter: "Fiică",
  relative_reason_daughter_in_law: "Noră",
  relative_reason_father: "Tată",
  relative_reason_father_in_law: "Socru",
  relative_reason_grandchild: "Nepot",
  relative_reason_grandparent: "Bunic / Bunică",
  relative_reason_husband: "Soț",
  relative_reason_mother: "Mamă",
  relative_reason_mother_in_law: "Soacră",
  relative_reason_parent: "Părinte",
  relative_reason_sister: "Soră",
  relative_reason_sister_in_law: "CumătrăCumnată",
  relative_reason_son: "Fiul",
  relative_reason_son_in_law: "Ginere",
  relative_reason_step_child: "Copil vitreg",
  relative_reason_step_parents: "Părinți vitregi",
  relative_reason_step_sibling: "Frate/soră vitreg(ă)",
  relative_reason_unmarried_partner: "Partener necăsătorit",
  relative_reason_wife: "Soție",
  // ------------ Relatives Type of Stay ------------
  typeofstay_none: "selectați o opțiune",
  typeofstay_temporary: "Viză temporară",
  typeofstay_permanently: "Cu ședere permanentă",
  typeofstay_other: "Fără viză și fără ședere permanentă",
  typeofstay_cannotcontact: "Nu pot să-i contactez",
  // ------------ Steps ------------
  step_0: "Început",
  step_1: "Informații generale",
  step_2: "Stare civilă",
  step_3: "Călătorii",
  step_4: "Informații suplimentare",
  step_text_finished: "Toate datele au fost completate - sunteți gata!",
  button_next: "Următoarea",
  button_back: "Anterioara",
  button_finish: "Finalizare",
  // ------------ Dividers ------------
  divider_general_information: "Informații generale",
  divider_birth_information: "Informații despreData și locul nașterii  naștere",
  divider_passport_information: "Informații despreDate pașaport",
  divider_residency_information: "Informații despreAdresa domiciliu",
  divider_phone_information: "Informații despreNumar de telefon",
  //
  divider_marital_status: "Stare civilă",
  divider_children: "Copii",
  divider_partner_details: "Detalii despre partener",
  divider_parents: "Detalii despre părinți",
  divider_mother_details: "Detalii Informatii despre mamă",
  divider_father_details: "Detalii Informatii despre tată",
  divider_emergency_contact: "Contact de urgență",
  divider_additional_information: "Informații suplimentare",
  divider_relatives_UK: "Rudeni în Marea Britanie",
  //
  divider_uk_status: "Informații despre Regatul Unit",
  divider_travel_UK: "Călătorii către Regatul Unit",
  divider_travel_abroad: "Călătorii în străinătate",
  divider_final_provisions: "Dispoziții finale",
  divider_data_privacy: "Protecția datelor",
  divider_sufficient_funds: "Fonduri suficiente",

  // ------------- Welcome Page -------------
  welcome_title: "Muncă în Regatul Unit cu viză",
  welcome_hint1:
    "Vă rugăm să completați răspunsurile la întrebări în LIMBA LATINĂcu caractere latine. Întrebările marcate cu (*) sunt obligatorii.",
  welcome_hint2:
    "Serviciul nostru este GRATUIT! Vă rugăm să nu plătiți pe nimeni! Taxa de viză este de 298 lire sterline britanice (363 EUR/ 386 USD) și taxa TLS / pentru anumite țări / se plătește cu cardul de debit sau credit către Serviciul de Vize Britanic UKVI. Nu avem agenți! Solicitările sunt făcute direct!",
  // ------------- General Information Page -------------
  name: "Nume",
  name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  surname: "Prenume",
  surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  maiden_name: "Numele dinaintea casatoriei de fată (dacă există)",
  maiden_name_error: "Numele dinaintea casatorieie fată trebuie să conțină doar litere!",
  gender: "Gen",
  gender_error: "Genul este obligatoriu!",
  sex_male: "Bărbat",
  sex_female: "Femeie",
  sex_diverse: "DiverseAltele",
  birth_date: "Data nașterii",
  birth_date_error: "Data nașterii este obligatorie și trebuie să fie cu cel puțin 17 ani în urmă.",
  birth_place: "Locul nașterii (oraș)",
  birth_place_error: "Locul nașterii este obligatoriu!",
  pass_id: "Număr de pașaport",
  pass_id_error: "Numărul de pașaport este obligatoriu și trebuie să conțină doar litere și cifre!",
  pass_issuing_place: "Locul de emiteriie",
  pass_issuing_place_error:
    "Locul de emiteriie este obligatoriu și trebuie să conțină doar litere!",
  pass_issue_date: "Data emiterii",
  pass_issue_date_error: "Data emiterii pașaportului este obligatorie și trebuie să fie în trecut.",
  pass_expiry_date: "Data expirării",
  pass_expiry_date_error:
    "Data expirării pașaportului este obligatorie și trebuie să fie în viitor!",
  biometric_pass_error: "Vă rugăm să selectați tipul de pașaport!",
  full_address: "Adresa completă (stradă, nr., Oraș)",
  residency_street: "Adresa completă (stradă, nr., etaj, apartament)",
  residency_street_error: "Adresa este obligatorie!",
  residency_city: "Adresa completă (Oraș/Sat)",
  residency_city_error: "Adresa este obligatorie și trebuie să conțină doar litere!",
  residency_province: "Județ / Regiune",
  residency_province_error: "Județul trebuie să conțină doar litere!",
  residency_zip: "Cod poștal",
  residency_zip_error: "Codul poștal este obligatoriu!",
  residency_date_since: "De când locuiți la această adresă?",
  residency_date_since_error: "Data de stabilire este obligatorie și trebuie să fie în trecut!",
  pass_biometric: "Aveți un pașaport biometric?",
  nationality: "Naționalitate",
  nationality_error: "Naționalitatea este obligatorie!",
  additional_nationality: "Aveți o altă naționalitate?",
  additional_nationality_error: "",
  birth_country: "Țara de naștere",
  birth_country_error: "Țara de naștere este obligatorie!",
  pass_issuing_country: "Țara emitentă",
  pass_issuing_country_error: "Țara emitentă este obligatorie!",
  residency_country: "Țara de reședință",
  residency_country_error: "Țara de reședință este obligatorie!",
  ownership_own: "Dețin",
  ownership_rent: "Închiriat",
  ownership_other: "Altul",
  residency_ownership: "Locuiți în acest loc?",
  residency_ownership_error: "Vă rugăm să selectați statutul potrivit pentru această locuință!",
  residency_ownership_other: "Vă rugăm să specificați",
  residency_ownership_other_error:
    "Vă rugăm să specificați relația cu locuința în litere latineșticaractere latine!",
  phone: "Număr de telefon",
  phone_error: "Numărul de telefon este obligatoriu și trebuie să conțină doar cifre!",
  // ------------- Family Information Page -------------
  marital_status: "Stare civilă",
  marital_status_error: "Starea civilă este obligatorie!",
  marital_status_single: "Necăsătorit (nemăritată)",
  marital_status_married: "Căsătorit (căsătorită)",
  marital_status_divorsed: "Divorțat (divorțată)",
  has_children: "Aveți copii?",
  has_children_error: "Este necesară informația despre cel puțin un copil!",
  child_0_name: "Numele primului copil",
  child_1_name: "Numele celui de-al doilea copil",
  child_2_name: "Numele celui de-al treilea copil",
  child_3_name: "Numele celui de-al patrulea copil",
  child_0_surname: "Prenumele primului copil",
  child_1_surname: "Prenumele celui de-al doilea copil",
  child_2_surname: "Prenumele celui de-al treilea copil",
  child_3_surname: "Prenumele celui de-al patrulea copil",
  child_0_birthday: "Data nașterii primului copil",
  child_1_birthday: "Data nașterii celui de-al doilea copil",
  child_2_birthday: "Data nașterii celui de-al treilea copil",
  child_3_birthday: "Data nașterii celui de-al patrulea copil",
  child_0_lives_home: "Locuiește cu voi",
  child_1_lives_home: "Locuiește cu voi",
  child_2_lives_home: "Locuiește cu voi",
  child_3_lives_home: "Locuiește cu voi",
  //
  child_0_name_error: "Numele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_1_name_error: "Numele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_2_name_error: "Numele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_3_name_error: "Numele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_0_surname_error: "Prenumele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_1_surname_error: "Prenumele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_2_surname_error: "Prenumele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_3_surname_error: "Prenumele copilului este obligatoriu și trebuie să conțină doar litere!",
  child_0_birthday_error: "Este necesar să specificați data nașterii copilului!",
  child_1_birthday_error: "Este necesar să specificați data nașterii copilului!",
  child_2_birthday_error: "Este necesar să specificați data nașterii copilului!",
  child_3_birthday_error: "Este necesar să specificați data nașterii copilului!",
  //
  partner_name: "Nume",
  partner_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  partner_surname: "Prenume",
  partner_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  partner_nationality: "Naționalitate",
  partner_nationality_error: "Naționalitatea este obligatorie!",
  partner_birthday: "Data nașterii",
  partner_birthday_error:
    "Data nașterii este obligatorie și trebuie să fie cu cel puțin 17 ani în urmă.",
  partner_birth_place: "Locul nașterii (oraș)",
  partner_birth_place_error: "Locul nașterii este obligatoriu!",
  partner_birth_country: "Țara de nașteriie",
  partner_birth_country_error: "Țara de nașteriie este obligatorie!",
  partner_livesHome: "Partenerul locuiește cu voi?",
  partner_livesHome_error: "Vă rugăm să selectați o opțiune potrivită!",
  //
  parents_unknown: "Nu pot furniza date despre părinții mei",
  //
  mother_name: "Nume",
  mother_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  mother_surname: "Prenume",
  mother_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  mother_nationality: "Naționalitate",
  mother_nationality_error: "Naționalitatea este obligatorie!",
  mother_birthday: "Data nașterii",
  mother_birthday_error:
    "Data nașterii este obligatorie și trebuie să fie cu cel puțin 17 ani în urmă.",
  mother_birthPlace: "Locul nașterii (oraș)",
  mother_birthPlace_error: "Locul nașterii este obligatoriu!",
  mother_birthCountry: "Țara de nașteriie",
  mother_birthCountry_error: "Țara de nașteriie este obligatorie!",
  //
  father_name: "Nume",
  father_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  father_surname: "Prenume",
  father_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  father_nationality: "Naționalitate",
  father_nationality_error: "Naționalitatea este obligatorie!",
  father_birthday: "Data nașterii",
  father_birthday_error:
    "Data nașterii este obligatorie și trebuie să fie cu cel puțin 17 ani în urmă.",
  father_birthPlace: "Locul nașterii (oraș)",
  father_birthPlace_error: "Locul nașterii este obligatoriu!",
  father_birthCountry: "Țara de nașteriie",
  father_birthCountry_error: "Țara de nașteriie este obligatorie!",
  //
  immergency_contact_name: "Numele complet al persoanei de contact in caz de urgență",
  immergency_contact_name_error:
    "Numele persoanei de contact in caz contactului de urgență este obligatoriu și trebuie să conțină doar litere!",
  immergency_contact_phone: "Număr de telefon",
  immergency_contact_phone_error:
    "Numărul de telefon este obligatoriu și trebuie să conțină doar cifre!",
  immergency_contact_relation: "Care este relația dvs. cu contactul dvs.?",
  immergency_contact_relation_error: "Relația este obligatorie!",
  immergency_contact_relation_relatives: "Rudă",
  immergency_contact_relation_parent: "Părinte",
  immergency_contact_relation_friend: "Prieten",
  //
  children_travelling: "Vor călători copiii cu dumneavoastră?",
  //
  has_relatives_in_UK: "Aveți rude în Marea Britanie?",
  has_relatives_in_UK_error: "Datele despre cel puțin un o rudă trebuie să fie introduse!",

  relative_0_name: "Numele primei rude",
  relative_1_name: "Numele celei de-a doua rude",
  relative_2_name: "Numele celei de-a treia rude",
  relative_3_name: "Numele celei de-a patra rude",

  relative_0_surname: "Prenumele primei rude",
  relative_1_surname: "Prenumele celei de-a doua rude",
  relative_2_surname: "Prenumele celei de-a treia rude",
  relative_3_surname: "Prenumele celei de-a patra rude",

  relative_0_nationality: "Naționalitatea primei rude",
  relative_1_nationality: "Naționalitatea celei de-a doua rude",
  relative_2_nationality: "Naționalitatea celei de-a treia rude",
  relative_3_nationality: "Naționalitatea celei de-a patra rude",

  relative_0_relation: "Relația dvs. cu prima rudă",
  relative_1_relation: "Relația dvs. cu a doua rudă",
  relative_2_relation: "Relația dvs. cu a treia rudă",
  relative_3_relation: "Relația dvs. cu a patra rudă",

  relative_0_typeofstay: "Statutul Tipul șederii primei rude",
  relative_1_typeofstay: " Tipul Statutul șederii celei de-a doua rude",
  relative_2_typeofstay: " Tipul Statutul șederii celei de-a treia rude",
  relative_3_typeofstay: " Tipul Statutul șederii celei de-a patra rude",

  relative_0_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  relative_1_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  relative_2_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",
  relative_3_name_error: "Numele este obligatoriu și trebuie să conțină doar litere!",

  relative_0_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  relative_1_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  relative_2_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",
  relative_3_surname_error: "Prenumele este obligatoriu și trebuie să conțină doar litere!",

  relative_0_nationality_error: "Naționalitatea este obligatorie!",
  relative_1_nationality_error: "Naționalitatea este obligatorie!",
  relative_2_nationality_error: "Naționalitatea este obligatorie!",
  relative_3_nationality_error: "Naționalitatea este obligatorie!",

  relative_0_relation_error: "Alegeți relația cu ruda!",
  relative_1_relation_error: "Alegeți relația cu ruda!",
  relative_2_relation_error: "Alegeți relația cu ruda!",
  relative_3_relation_error: "Alegeți relația cu ruda!",

  relative_0_typeofstay_error: "Alegeți tipul statutul șederii!",
  relative_1_typeofstay_error: "Alegeți tipul statutul șederii!",
  relative_2_typeofstay_error: "Alegeți tipul statutul șederii!",
  relative_3_typeofstay_error: "Alegeți tipul statutul șederii!",
  // ------------- Travel Information Page -------------
  UK_insurance: "Ați obținut un număr de asigurare național din Marea Britanie?",
  UK_insurance_number: "Numărul de asigurare național",
  UK_insurance_number_error: "Vă rugăm să introduceți numărul dvs. de asigurare național!",
  visited_UK_doctor: "Ați consultat un medic în Marea Britanie?",
  granted_UK_visa: "Vi s-a acordat o viză pentru Marea Britanie?",
  refused_visa: "Vi s-a refuzat viza pentru Marea Britanie anterior?",
  refused_entry: "Vi s-a refuzat intrarea în Marea Britanie?",
  has_worked_illegally: "Ați lucrat vreodată ilegal în Marea Britanie?",
  required_to_leave_UK: "Vi s-a cerut să părăsiți Marea Britanie?",
  has_applied_for_visa:
    "Ați depus vreodată documente pentru statut sau ședere în Marea Britanie în ultimii 10 ani?",
  //
  has_travelled_to_UK: "Ați călătorit în Marea Britanie anterior?",
  has_travelled_to_UK_error: "Trebuie să introduceți cel puțin o călătorie!",
  travel_uk_hint: "Introduceți cele mai recente 3 călătorii în Marea Britanie.",
  //
  traveluk_0_from: "Data de început a primei călătorii",
  traveluk_1_from: "Data de început a celei de-a doua călătorii",
  traveluk_2_from: "Data de început a celei de-a treia călătorii",
  traveluk_3_from: "Data de început a celei de-a patra călătorii",

  traveluk_0_to: "Data de sfârșit a primei călătorii",
  traveluk_1_to: "Data de sfârșit a celei de-a doua călătorii",
  traveluk_2_to: "Data de sfârșit a celei de-a treia călătorii",
  traveluk_3_to: "Data de sfârșit a celei de-a patra călătorii",

  traveluk_0_reason: "Motivul primei călătorii",
  traveluk_1_reason: "Motivul celei de-a doua călătorii",
  traveluk_2_reason: "Motivul celei de-a treia călătorii",
  traveluk_3_reason: "Motivul celei de-a patra călătorii",

  traveluk_0_from_error:
    "Trebuie să specificați data de început a șederii dumneavoastră în Marea Britanie!",
  traveluk_1_from_error:
    "Trebuie să specificați data de început a șederii dumneavoastră în Marea Britanie!",
  traveluk_2_from_error:
    "Trebuie să specificați data de început a șederii dumneavoastră în Marea Britanie!",
  traveluk_3_from_error:
    "Trebuie să specificați data de început a șederii dumneavoastră în Marea Britanie!",

  traveluk_0_to_error:
    "Trebuie să specificați data de sfârșit a șederii dumneavoastră în Marea Britanie!",
  traveluk_1_to_error:
    "Trebuie să specificați data de sfârșit a șederii dumneavoastră în Marea Britanie!",
  traveluk_2_to_error:
    "Trebuie să specificați data de sfârșit a șederii dumneavoastră în Marea Britanie!",
  traveluk_3_to_error:
    "Trebuie să specificați data de sfârșit a șederii dumneavoastră în Marea Britanie!",

  traveluk_0_reason_error:
    "Trebuie să specificați motivul șederii dumneavoastră în Marea Britanie!",
  traveluk_1_reason_error:
    "Trebuie să specificați motivul șederii dumneavoastră în Marea Britanie!",
  traveluk_2_reason_error:
    "Trebuie să specificați motivul șederii dumneavoastră în Marea Britanie!",
  traveluk_3_reason_error:
    "Trebuie să specificați motivul șederii dumneavoastră în Marea Britanie!",
  //
  refused_visa_abroad: "V-a fost refuzată viza pentru o altă țară?",
  deported_abroad: "Ați fost deportat din altă țară?",
  deported_abroad_details: "Dacă ați fost deportat din altă țară, vă rugăm să furnizați detalii",
  deported_abroad_details_error:
    "Dacă ați răspuns cu „da” la întrebarea anterioară, trebuie să furnizați informații detaliate!",
  //
  has_travelled_abroad: "Ați călătorit în străinătate în ultimii 10 ani?",
  has_travelled_abroad_error: "Trebuie să introduceți cel puțin o călătorie!",
  destination_0: "Prima țară",
  destination_1: "A doua țară",
  destination_2: "A treia țară",
  destination_3: "A patra țară",
  destination_4: "A cincea țară",
  travelabroad_0_from: "Data de început a primei călătorii",
  travelabroad_1_from: "Data de început a celei de-a doua călătorii",
  travelabroad_2_from: "Data de început a celei de-a treia călătorii",
  travelabroad_3_from: "Data de început a celei de-a patra călătorii",
  travelabroad_4_from: "Data de început a celei de-a cincea călătorii",
  travelabroad_0_to: "Data de sfârșit a primei călătorii",
  travelabroad_1_to: "Data de sfârșit a celei de-a doua călătorii",
  travelabroad_2_to: "Data de sfârșit a celei de-a treia călătorii",
  travelabroad_3_to: "Data de sfârșit a celei de-a patra călătorii",
  travelabroad_4_to: "Data de sfârșit a celei de-a cincea călătorii",
  travelabroad_0_reason: "Motivul primei călătorii",
  travelabroad_1_reason: "Motivul celei de-a doua călătorii",
  travelabroad_2_reason: "Motivul celei de-a treia călătorii",
  travelabroad_3_reason: "Motivul celei de-a patra călătorii",
  travelabroad_4_reason: "Motivul celei de-a cincea călătorii",

  destination_0_error:
    "Trebuie să specificați țara în care ați locuit! Pentru Marea Britanie, vă rugăm să utilizați secțiunea anterioară.",
  destination_1_error:
    "Trebuie să specificați țara în care ați locuit! Pentru Marea Britanie, vă rugăm să utilizați secțiunea anterioară.",
  destination_2_error:
    "Trebuie să specificați țara în care ați locuit! Pentru Marea Britanie, vă rugăm să utilizați secțiunea anterioară.",
  destination_3_error:
    "Trebuie să specificați țara în care ați locuit! Pentru Marea Britanie, vă rugăm să utilizați secțiunea anterioară.",
  destination_4_error:
    "Trebuie să specificați țara în care ați locuit! Pentru Marea Britanie, vă rugăm să utilizați secțiunea anterioară.",
  travelabroad_0_from_error: "Trebuie să specificați data de început a șederii în trecut!",
  travelabroad_1_from_error: "Trebuie să specificați data de început a șederii în trecut!",
  travelabroad_2_from_error: "Trebuie să specificați data de început a șederii în trecut!",
  travelabroad_3_from_error: "Trebuie să specificați data de început a șederii în trecut!",
  travelabroad_4_from_error: "Trebuie să specificați data de început a șederii în trecut!",
  travelabroad_0_to_error: "Trebuie să specificați data de sfârșit a șederii în trecut!",
  travelabroad_1_to_error: "Trebuie să specificați data de sfârșit a șederii în trecut!",
  travelabroad_2_to_error: "Trebuie să specificați data de sfârșit a șederii în trecut!",
  travelabroad_3_to_error: "Trebuie să specificați data de sfârșit a șederii în trecut!",
  travelabroad_4_to_error: "Trebuie să specificați data de sfârșit a șederii în trecut!",
  travelabroad_0_reason_error: "Trebuie să specificați motivul șederii în străinătate!",
  travelabroad_1_reason_error: "Trebuie să specificați motivul șederii în străinătate!",
  travelabroad_2_reason_error: "Trebuie să specificați motivul șederii în străinătate!",
  travelabroad_3_reason_error: "Trebuie să specificați motivul șederii în străinătate!",
  travelabroad_4_reason_error: "Trebuie să specificați motivul șederii în străinătate!",
  // ------------- Additional Information Page -------------
  convictions: "Ați fost condamnat/ă? (inclusiv pentru încălcări rutiere)",
  war_crimes: "Ați participat în conflicte armate?",
  terrorist_org_member: "Ați participat/susținut organizații teroriste?",
  not_good_character:
    "Ați fost implicat/ă în activități care ar putea crea o impresie negativă despre dumneavoastră?",
  application_additional_information:
    "Doriți să furnizați informații suplimentare în cadrul acestei aplicații?",
  work_experience: "Vă rugăm să descrieți experiența dvs. de muncă",
  has_health_issues: "Aveți probleme de sănătate/alergii? (Vă rugăm să descrieți)",
  english_level: "Nivel de limba engleză",
  english_level_error: "Vă rugăm să specificați nivelul limbiidvs. de englezeă!",
  language_level_none: "Niciunul",
  language_level_beginner: "Începător",
  language_level_intermediate: "Intermediar",
  language_level_advanced: "Avansat",
  has_driving_license: "Dețineți permis de conducere?",
  has_driving_license_error: "Vă rugăm să specificați dacă dețineți permis de conducere!",
  //
  privacy_hint:
    "De ce avem nevoie de datele dvs. personale și cum le vom procesa? " +
    "IBS Student Exchange Ltd. este administratorul datelor dvs. personale " +
    "și le procesează conform Regulamentului General privind Protecția Datelor (GDPR). " +
    "Colectarea datelor dvs. personale este necesară în legătură cu candidatura " +
    "dvs. pentru participarea la programele noastre. Aici puteți citi informații detaliate " +
    "despre Politica noastră de confidențialitate: ",
  privacy_policy:
    "Confirm că am citit în detaliu, înțeleg " +
    "și sunt de acord cu Politica de Confidențialitate",
  privacy_policy_error:
    "Vă rugăm să acceptați politica de confidențialitate, " +
    "pentru a putea continua procesul de candidatură!",
  //
  sufficient_funds:
    "Declar că dispun de fonduri suficiente pentru a acoperi testul de depistare a tuberculozei, viza și costurile de călătorie către și dinspre Regatul Unit.",
  sufficient_funds_error: "Vă rugăm să confirmați disponibilitatea fondurilor!",
  // ------------- Candidate Registration Page -------------
  register_finished_title:
    "Vă mulțumim pentru înregistrare. Vom lua legătura cu dvs. în legătură cu pașii următori.",
  register_welcome_title: "Muncă în Marea Britanie cu viză",
  register_welcome_hint1:
    "Aici vă puteți înregistra pentru interviu completând numele dvs., adresa de email și numărul de telefon.",
  button_register: "Înregistrare",
  email: "Adresa de email",
  has_passport: "Aveți pașaport?",
  passportId: "Număr de pașaport",
};

export default ro;
