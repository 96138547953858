import { ReactElement } from "react";

export type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  icon?: ReactElement;
  tooltip?: string;
  width?: string;
}

export type HeadCells = HeadCell[];

function defaultStringComparator<T>(a: [string, T], b: [string, T], orderBy: keyof T): number {
  const c1 = a[1][orderBy];
  const c2 = b[1][orderBy];

  if (!orderBy) {
    return 0;
  }

  let res = 0;
  if (c1 && c2) {
    if (c2 < c1) {
      res = -1;
    }
    if (c2 > c1) {
      res = 1;
    }
  } else {
    if (c1 && !c2) {
      res = -1;
    } else if (!c1 && c2) {
      res = 1;
    }
  }

  return res;
}

export function defaultNumberComparator<T>(
  a: [string, T],
  b: [string, T],
  orderBy: keyof T
): number {
  const c1 = a[1][orderBy] as number;
  const c2 = b[1][orderBy] as number;

  if (!orderBy) {
    return 0;
  }

  if (c1 && c2) {
    return c2 - c1;
  }

  if (c1 && !c2) {
    return -1;
  }

  if (!c1 && c2) {
    return 1;
  }

  return 0;
}

export function getComparator<T>(
  order: Order,
  orderBy: keyof T,
  descendingComparator?: (a: [string, T], b: [string, T], orderBy: keyof T) => number
): (a: [string, T], b: [string, T]) => number {
  const comparator = descendingComparator || defaultStringComparator;
  return order === "desc"
    ? (a, b) => comparator(a, b, orderBy)
    : (a, b) => -comparator(a, b, orderBy);
}
