import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Sponsor, SponsorDoc, SponsorsDict } from "../types/sponsors";
import db from "../types/firebase";
import { AppUser } from "../types/users";
import { Employer, EmployerDoc, EmployersDict } from "../types/orders";
import { Country, Regions } from "../types/commons";

export const loadSponsor = async (sponsorId: string) => {
  let loadedSponsor = null;

  if (sponsorId) {
    const docRef = doc(db, "sponsors", sponsorId);
    const sponsorSnapshot = await getDoc(docRef);
    if (sponsorSnapshot) {
      loadedSponsor = {
        id: sponsorSnapshot.id,
        sponsor: sponsorSnapshot.data() as Sponsor,
      };
    }
  }
  return loadedSponsor;
};

export const loadSponsors = async (user: AppUser | null | undefined) => {
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";

  let sponsorsRef;
  let sponsorsSnapshot;
  const sponsorsDict: SponsorsDict = {};

  if (sponsorId) {
    sponsorsRef = doc(db, "sponsors", sponsorId);
    const sponsorSnapshot = await getDoc(sponsorsRef);

    if (sponsorSnapshot && sponsorSnapshot.data()) {
      sponsorsDict[sponsorId] = sponsorSnapshot.data() as Sponsor;
    }
  } else {
    sponsorsRef = collection(db, "sponsors");
    sponsorsSnapshot = await getDocs(sponsorsRef);
    sponsorsSnapshot.forEach((doc) => {
      const sponsor = doc.data() as Sponsor;
      sponsorsDict[doc.id] = sponsor;
    });
  }
  return sponsorsDict;
};

export const storeSponsor = async (
  user: AppUser | null | undefined,
  sponsorDoc: SponsorDoc | null,
  addIfNew: boolean
) => {
  if (!(sponsorDoc && sponsorDoc.sponsor)) {
    return null;
  }

  let sponsorSnapshot = null;

  if (sponsorDoc.id) {
    const docRef = doc(db, "sponsors", sponsorDoc.id);
    sponsorSnapshot = await getDoc(docRef);
    // Update the document
    if (sponsorSnapshot) {
      sponsorDoc.sponsor.updatedAt = new Date().toISOString();
      sponsorDoc.sponsor.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, sponsorDoc.sponsor);
      console.log("updated sponsor:", sponsorDoc.id);
    }
  } else if (addIfNew) {
    sponsorDoc.sponsor.createdAt = new Date().toISOString();
    sponsorDoc.sponsor.createdBy = user ? user.id : "anonymous";
    sponsorDoc.id = (await addDoc(collection(db, "sponsors"), sponsorDoc.sponsor)).id;
    console.log("created sponsor:", sponsorDoc.id);
  } else {
    return null;
  }

  return { ...sponsorDoc };
};

export const deleteSponsor = async (
  user: AppUser | null | undefined,
  sponsorId: string
): Promise<string> => {
  if (!sponsorId) return "no-sponsor";
  if (!user || !user.role.includes("admin")) {
    return "insufficient-permissions";
  }

  const collections = ["employers", "applicants", "applications", "campaigns"];
  for (let collectionName of collections) {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("sponsorId", "==", sponsorId), limit(1));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      console.log(`Found document with sponsorId ${sponsorId} in ${collectionName}`);
      return "sponsor-in-use";
    }
  }

  await deleteDoc(doc(db, "sponsors", sponsorId));
  return "success";
};

export const loadEmployers = async (user: AppUser | null | undefined) => {
  let employersRef;
  let employersSnapshot;
  const employersDict: EmployersDict = {};

  // const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  // if (sponsorId) {
  //   employersRef = query(collection(db, "employers"), where("sponsorId", "==", sponsorId));
  // } else {
  employersRef = collection(db, "employers");
  //  }

  employersSnapshot = await getDocs(employersRef);
  employersSnapshot.forEach((doc) => {
    const employer = doc.data() as Employer;
    employersDict[doc.id] = employer;
  });
  return employersDict;
};

export const storeEmployer = async (
  user: AppUser | null | undefined,
  employerDoc: EmployerDoc | null,
  addIfNew: boolean
) => {
  if (!(employerDoc && employerDoc.employer)) {
    return null;
  }

  const empDoc = { ...employerDoc };

  if (employerDoc.id) {
    const docRef = doc(db, "employers", empDoc.id);
    const employerSnapshot = await getDoc(docRef);
    // Update the document
    if (employerSnapshot) {
      empDoc.employer.updatedAt = new Date().toISOString();
      empDoc.employer.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, empDoc.employer);
      console.log("updated employer:", empDoc.id);
    }
  } else if (addIfNew) {
    empDoc.employer.createdAt = new Date().toISOString();
    empDoc.employer.createdBy = user ? user.id : "anonymous";
    empDoc.id = (await addDoc(collection(db, "employers"), empDoc.employer)).id;
    console.log("created employer:", empDoc.id);
  }
  return empDoc;
};

export const deleteEmployer = async (
  user: AppUser | null | undefined,
  employerId: string
): Promise<string> => {
  if (!employerId) return "no-employer";
  if (!user || !user.role.includes("admin")) {
    return "insufficient-permissions";
  }

  const collections = ["applications", "orders"];
  for (let collectionName of collections) {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("employerId", "==", employerId), limit(1));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      console.log(`Found document with employerId ${employerId} in ${collectionName}`);
      return "employer-in-use";
    }
  }

  await deleteDoc(doc(db, "employers", employerId));
  return "success";
};

export const loadRegions = async (country: Country) => {
  const regionsRef = collection(db, "regions");
  const q = query(regionsRef, where("country.code", "==", country.code));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }
  const regions: Regions = querySnapshot.docs[0].data() as Regions;
  return regions;
};
