import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  MenuItem,
  Divider,
  ListItemIcon,
  Menu,
  TextField,
} from "@mui/material";
//import PersonAdd from "@mui/icons-material/PersonAdd";
// import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import header_icon from "../ressources/ibc_icon.png";

import { AuthContext } from "./auth-provider";
import { signOut } from "firebase/auth";
import { auth } from "../types/firebase";
import { AppUser, EMPTY_APP_USER, UserSettingsMode } from "../types/users";
import UserSettingsDialog from "../pages/dialogs/dialogUserSettings";
import { headerColor } from "../theme";
import AboutDialog from "../pages/dialogs/dialogAbout";
import { hasRole } from "../utils/user-utils";
import { SponsorsDict } from "../types/sponsors";
import { loadSponsors } from "../data-functions/system-data_api";
import { AgentsDict } from "../types/agents";
import { loadAgents } from "../data-functions/agent-api";
import { loadAppUser } from "../data-functions/users-api";

const Header: React.FC = () => {
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [agents, setAgents] = useState<AgentsDict>({});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<AppUser>({ ...EMPTY_APP_USER });
  const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(false);
  const [aboutDialogOpen, setAboutDialogOpen] = useState<boolean>(false);
  const [profileMode, setProfileMode] = useState<UserSettingsMode>("edit");

  const [showHeader, setShowHeader] = useState<boolean>(true);

  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const openSettingsMenu = Boolean(settingsAnchorEl);

  const [applicationsAnchorEl, setApplicationsAnchorEl] = useState<null | HTMLElement>(null);
  const openApplicationsMenu = Boolean(applicationsAnchorEl);

  const [recruitingAnchorEl, setRecruitingAnchorEl] = useState<null | HTMLElement>(null);
  const openRecruitingMenu = Boolean(recruitingAnchorEl);

  const openProfileMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const path = useLocation().pathname;

  const { currentUser, setCurrentUser } = useContext(AuthContext)!;

  const fetchSponsors = async () => {
    const sponsorId = currentUser?.realSponsorId || "";
    const appUser: AppUser | null | undefined = currentUser?.appUser
      ? { ...currentUser?.appUser }
      : null;
    if (appUser) {
      appUser.sponsorId = sponsorId;
    }
    const sponsorsDict: SponsorsDict = await loadSponsors(appUser);
    setSponsors(sponsorsDict);
  };

  const fetchAgents = async () => {
    const agentId = currentUser?.realAgentId || "";
    const appUser: AppUser | null | undefined = currentUser?.appUser
      ? { ...currentUser?.appUser }
      : null;
    if (appUser) {
      appUser.agentId = agentId;
    }
    const agentsDict: AgentsDict = await loadAgents(appUser);
    setAgents(agentsDict);
  };

  const fetchUser = async () => {
    const fbUser = auth.currentUser;
    if (!fbUser) {
      return;
    }

    let appUser = await loadAppUser(fbUser.uid);

    if (!appUser) {
      appUser = {
        id: fbUser.uid,
        name: "",
        surname: "",
        email: fbUser.email ? fbUser.email : "",
        sponsorId: "",
        role: [],
      };
    }

    setUser(appUser);
    setCurrentUser({ ...currentUser, appUser: appUser, realSponsorId: appUser.sponsorId || "" });
  };

  useEffect(() => {
    const newAppUser =
      currentUser && currentUser.appUser ? currentUser.appUser : { ...EMPTY_APP_USER };

    const isApplicant =
      !currentUser || !currentUser.appUser || currentUser.appUser.role.includes("applicant");

    setUser(newAppUser);

    const canShowHeader = !(
      (path.includes("/questionnaire") && isApplicant) ||
      path.includes("/register") ||
      path.includes("/verify-registration") ||
      (path === "/" && !(currentUser && currentUser.authUser))
    );

    if (currentUser && currentUser.realSponsorId === "") {
      fetchSponsors();
    }

    if (currentUser && currentUser.realAgentId === "") {
      fetchAgents();
    }

    // console.log("canShowHeader", canShowHeader);
    // console.log("showHeader", canShowHeader && currentUser && currentUser.authUser ? true : false);
    setShowHeader(canShowHeader && currentUser && currentUser.authUser ? true : false);
  }, [currentUser]);

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleApplicationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setApplicationsAnchorEl(event.currentTarget);
  };

  const handleApplicationsClose = () => {
    setApplicationsAnchorEl(null);
  };

  const handleRecruitingClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecruitingAnchorEl(event.currentTarget);
  };

  const handleRecruitingClose = () => {
    setRecruitingAnchorEl(null);
  };

  const handleProfileOpen = () => {
    setProfileMode("edit");
    setProfileDialogOpen(true);
    handleClose();
  };

  const handleAboutOpen = () => {
    setAboutDialogOpen(true);
    handleClose();
  };

  const handleProfileClose = () => {
    if (profileMode === "edit") {
      // make sure to update the object, in case of any changes
      fetchUser();
    }
    setProfileDialogOpen(false);
  };

  // const handleAddUserOpen = () => {
  //   setProfileMode("add");
  //   setProfileDialogOpen(true);
  //   handleClose();
  // };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
    handleClose();
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "sponsorId":
        if (currentUser?.appUser) {
          const appUser: AppUser = { ...currentUser.appUser };
          appUser.sponsorId = value;
          if (appUser.agentId) {
            if (agents[appUser.agentId].sponsorId !== value) {
              appUser.agentId = "";
            }
          }
          setCurrentUser({ ...currentUser, appUser: appUser });
        }
        break;

      case "agentId":
        if (currentUser?.appUser) {
          const appUser: AppUser = { ...currentUser.appUser };
          appUser.agentId = value;
          setCurrentUser({ ...currentUser, appUser: appUser });
        }
        break;

      default:
        break;
    }
  };

  if (showHeader) {
    return (
      <AppBar position="static">
        <Toolbar>
          <Avatar alt="IBC" src={header_icon} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, paddingLeft: "10px" }}>
            Global Workforce (Beta)
          </Typography>
          {/* ---------- Sponsor selector ---------- */}
          {currentUser?.realSponsorId === "" && (
            <Box sx={{ display: "flex", justifyContent: "left", gap: "10px" }}>
              <Typography
                variant="body1"
                component="div"
                sx={{ display: "flex", paddingLeft: "10px", alignItems: "center" }}
              >
                Sponsor
              </Typography>

              <TextField
                name={"sponsorId"}
                select
                onChange={onInputComboChange}
                value={currentUser.appUser?.sponsorId || ""}
                margin={"dense"}
                size="small"
                style={{ minWidth: "250px" }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selected: any) => {
                    if (selected === "") {
                      return <em>All sponsors</em>;
                    }
                    return <span> {sponsors[selected].name} </span>;
                  },
                }}
              >
                <MenuItem value="" key="no_sponsor">
                  <Typography fontStyle={"italic"}>All sponsors</Typography>
                </MenuItem>
                {Object.entries(sponsors).map(([sponsorId, sponsor]) => {
                  return (
                    <MenuItem value={sponsorId} key={sponsorId}>
                      {sponsor.name}
                    </MenuItem>
                  );
                })}
              </TextField>

              {/* Agents */}
              {currentUser?.realAgentId === "" && currentUser?.appUser?.sponsorId !== "" && (
                <TextField
                  name={"agentId"}
                  select
                  onChange={onInputComboChange}
                  value={currentUser.appUser?.agentId || ""}
                  margin={"dense"}
                  size="small"
                  style={{ minWidth: "250px" }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected: any) => {
                      if (selected === "") {
                        return <em>All Agents</em>;
                      }
                      return <span> {agents[selected].company} </span>;
                    },
                  }}
                >
                  <MenuItem value="" key="no_agent">
                    <Typography fontStyle={"italic"}>All agents</Typography>
                  </MenuItem>
                  {Object.entries(agents).map(([agentId, agent]) => {
                    return (
                      <MenuItem value={agentId} key={agentId}>
                        {agent.company}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Box>
          )}

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            {/* ---------- Recruiting ---------- */}

            {hasRole(currentUser?.appUser, ["admin", "recruiter"]) && (
              <React.Fragment>
                <Button
                  aria-controls={openRecruitingMenu ? "recruiting-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openRecruitingMenu ? "true" : undefined}
                  color="inherit"
                  sx={{ ":hover": { backgroundColor: headerColor } }}
                  onClick={handleRecruitingClick}
                >
                  Recruiting
                </Button>

                <Menu
                  anchorEl={recruitingAnchorEl}
                  id="recruiting-menu"
                  open={openRecruitingMenu}
                  onClose={handleRecruitingClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem component={Link} to="/campaigns" onClick={handleRecruitingClose}>
                    Campaigns
                  </MenuItem>
                  <MenuItem component={Link} to="/candidates" onClick={handleRecruitingClose}>
                    Candidates
                  </MenuItem>

                  <Divider key="divider_recruiting_1" variant="fullWidth" light />

                  <MenuItem component={Link} to="/interviews" onClick={handleRecruitingClose}>
                    Interviews
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}

            {/* ---------- Applications ---------- */}
            {hasRole(currentUser?.appUser, ["admin", "operator"]) && (
              <React.Fragment>
                <Button
                  aria-controls={openApplicationsMenu ? "applications-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openApplicationsMenu ? "true" : undefined}
                  color="inherit"
                  sx={{ ":hover": { backgroundColor: headerColor } }}
                  onClick={handleApplicationsClick}
                >
                  Applications
                </Button>
                {/* Applications Menu */}
                <Menu
                  anchorEl={applicationsAnchorEl}
                  id="applications-menu"
                  open={openApplicationsMenu}
                  onClose={handleApplicationsClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem component={Link} to="/orders" onClick={handleApplicationsClose}>
                    Employment Orders
                  </MenuItem>
                  <MenuItem component={Link} to="/applicants" onClick={handleApplicationsClose}>
                    Applicants
                  </MenuItem>
                  <MenuItem component={Link} to="/applications" onClick={handleApplicationsClose}>
                    Applications
                  </MenuItem>
                  <MenuItem component={Link} to="/presentations" onClick={handleApplicationsClose}>
                    Virtual Presentations
                  </MenuItem>
                  {hasRole(currentUser?.appUser, ["admin"]) && (
                    <Divider key="divider-applications-1" light />
                  )}
                  {hasRole(currentUser?.appUser, ["admin"]) && (
                    <MenuItem
                      component={Link}
                      to="/import-applicants"
                      onClick={handleApplicationsClose}
                    >
                      Import Applicants
                    </MenuItem>
                  )}
                </Menu>
              </React.Fragment>
            )}

            {/* ---------- System Data ---------- */}

            {hasRole(currentUser?.appUser, ["admin"]) && (
              <React.Fragment>
                <Button
                  aria-controls={openSettingsMenu ? "settings-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSettingsMenu ? "true" : undefined}
                  color="inherit"
                  sx={{ ":hover": { backgroundColor: headerColor } }}
                  onClick={handleSettingsClick}
                >
                  System Data
                </Button>

                {/* System Data Menu */}
                <Menu
                  anchorEl={settingsAnchorEl}
                  id="settings-menu"
                  open={openSettingsMenu}
                  onClose={handleSettingsClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  {!currentUser?.appUser?.sponsorId && (
                    <MenuItem component={Link} to="/sponsors" onClick={handleSettingsClose}>
                      Sponsors
                    </MenuItem>
                  )}
                  {!currentUser?.appUser?.sponsorId && (
                    <MenuItem component={Link} to="/employers" onClick={handleSettingsClose}>
                      Employers
                    </MenuItem>
                  )}
                  <MenuItem component={Link} to="/agents" onClick={handleSettingsClose}>
                    Agents
                  </MenuItem>
                  {!currentUser?.appUser?.sponsorId && <Divider key="divider3" light />}
                  {!currentUser?.appUser?.sponsorId && (
                    <MenuItem component={Link} to="/seasons" onClick={handleSettingsClose}>
                      Seasons
                    </MenuItem>
                  )}
                </Menu>
              </React.Fragment>
            )}
          </Box>

          {currentUser && currentUser.authUser ? (
            <Tooltip title="Profile">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openProfileMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfileMenu ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "white",
                    color: "#1976d2",
                  }}
                >
                  {user && user.name ? user.name[0] + user.surname[0] : ""}
                </Avatar>
              </IconButton>
            </Tooltip>
          ) : (
            <Button color="inherit">Login</Button>
          )}
        </Toolbar>
        <UserSettingsDialog
          user={user}
          mode={profileMode}
          open={profileDialogOpen}
          onClose={handleProfileClose}
        />
        <AboutDialog open={aboutDialogOpen} handleClose={() => setAboutDialogOpen(false)} />

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openProfileMenu}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
                minWidth: "250px",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleProfileOpen}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleAboutOpen}>
            <Box width={32} height={32} marginLeft="-4px" marginRight="8px" />
            About...
          </MenuItem>

          <Divider key="divider2" variant="inset" light />

          {currentUser &&
            currentUser.appUser &&
            !currentUser.appUser.sponsorId &&
            currentUser.appUser.role.includes("admin") && (
              <div>
                <MenuItem component={Link} to="/users" onClick={handleClose}>
                  <Box width={32} height={32} marginLeft="-4px" marginRight="8px" />
                  User Management
                </MenuItem>
                <Divider key="divider2" variant="inset" light />
              </div>
            )}

          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
    );
  } else {
    return <div></div>;
  }
};

export default Header;
