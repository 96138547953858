import { Timestamp } from "firebase/firestore";
import { Country, Region, TenantRecord, TrackedRecord } from "./commons";
import { QuestionAnswersDict } from "./surveys";

export const VERIFICATION_EXPIRY_HOURS = 12; // number of hours

export type CandidateStatus =
  | "new"
  | "verified"
  | "language_test"
  | "invited"
  | "interviewed"
  | "approved"
  | "rejected"
  | "DnI"
  | "applicant";
export type CampaignStatus = "open" | "closed";

export interface CandidateVerificaion {
  verificationCode: string;
  expiryDate: string;
  verifiedOn?: string;
  confirmationCode?: string;
}

export interface CandidateVerificaion_FB {
  verificationCode: string;
  expiryDate: Timestamp;
  verifiedOn?: Timestamp;
  confirmationCode?: string;
  restrictEmailProvider?: boolean;
  emailProviderUrl?: string;
}

export interface RecruitingCampaign extends TrackedRecord, TenantRecord {
  name: string;
  status: string;
  language?: string;
  country?: Country;
  showPaymentsDisclaimer?: boolean;
  showAdvancedForm?: boolean;
  restrictEmailProvider?: boolean;
  emailProviderUrl?: string;
  includeRegion?: boolean;
  inviteAutomatically?: boolean;
  verificationExpiryHours?: number;
}

export interface BaseCandidate extends TrackedRecord, TenantRecord {
  name: string;
  surname: string;
  email: string;
  phone: string;
  birth_date?: string;
  has_passport?: boolean;
  passportId?: string;
  country?: Country;
  region?: Region;
  status?: CandidateStatus;
  campaignId?: string;
  employerId?: string;
  note?: string;
  imageUrl?: string;
  privacy_policy_accepted?: boolean;
  surveyAnswers?: QuestionAnswersDict;
  surveyScore?: number;
  interviewer?: string;
  interviewerId?: string;
  applicantId?: string;
  preferredLanguage?: string;
}

export interface Candidate extends BaseCandidate {
  verification?: CandidateVerificaion;
}

export interface Candidate_FB extends BaseCandidate {
  verification?: CandidateVerificaion_FB;
}

export type CampaignsDict = Record<string, RecruitingCampaign>;
export type CandidatesDict = Record<string, Candidate>;

export type CampaignDoc = { id: string; campaign: RecruitingCampaign };
export type CandidateDoc = { id: string; candidate: Candidate };

export const EMPTY_CAMPAIGN: RecruitingCampaign = {
  name: "",
  sponsorId: "",
  status: "open",
  agentId: "",
  language: "en",
};

export const EMPTY_CANDIDATE = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  sponsorId: "",
  status: "new" as CandidateStatus,
};

export const EMPTY_CANDIDATE_DOC: CandidateDoc = {
  id: "",
  candidate: { ...EMPTY_CANDIDATE },
};

export interface CandidateErrors {
  name: string;
  surname: string;
  email: string;
  phone: string;
  privacy_policy_accepted: string;
  passportId: string;
  birth_date?: string;
  region?: string;
}

export const EMPTY_CANDIDATE_ERRORS = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  privacy_policy_accepted: "",
  passportId: "",
  birth_date: "",
  region: "",
};

export const CANDIDATE_PROPERTY_MAPPING: Record<string, string> = {
  name: "Name",
  surname: "Family name",
  email: "E-Mail",
  phone: "Phone",
  privacy_policy_accepted: "Privacy Policy accepted",
  status: "Status",
  qid_0001: "Previous experience in agriculture",
  qid_0002: "Which crops do you have experience growing/picking",
  qid_0003: "Preference for the type of crops to be picked?",
  qid_0004: "Have you ever been issued an UK visa before?",
  qid_0005: "If yes, what type of visa and when did it expire?",
  qid_0006: "Do you have a driving license?",
  qid_0007: "Hold a license for",
  qid_0007_01: "Hold a license for driving a tractor",
  qid_0007_02: "Hold a license for driving a forklift",
  qid_0007_03: "Hold a license for driving a spayer",
  qid_0008: "Have you worked in other countries?",
  qid_0009: "Do you suffer from any serious illnesses?",
  qid_0010: "If YES, please specify:",
  qid_0011: "Does this disease require medication?",
  qid_0012: "If YES, please specify:",
  qid_0013: "Do you have allergies?",
  qid_0014: "If YES, please specify",
  qid_0015: "Do you suffer from color blindness?",
  qid_0016: "Are you physically fit and able to work long hours in all kinds of weather?",
  qid_0017: "For how long can you stay in the UK?",
  qid_0018: "Would you work 40 plus hours a week?",
  qid_0019: "Do you have e preferred start date?",
  qid_0020: "What's the best part of working on a farm?",
  qid_0021: "Enough funds to cover the cost of visa fee and expenses",
  qid_0022: "How do you prefer to be paid - by piecerate or by timework?",
  qid_0023: "Are you afraid of heights (for mushroom pickers)?",
  qid_0024: "Expectations about earnings and savings from the work",
  qid_0025: "Do you prefer day or night shifts?",
};

export interface BasicRegistrationInfo {
  sponsorId: string;
  candidateId: string;
  campaignId: string;
  browserAgent: string;
  ip4Address: string;
  ip6Address: string;
  deviceId: string;
}

export interface RegistrationInfo extends BasicRegistrationInfo {
  createdAt: string;
}

export interface RegistrationInfo_FB extends BasicRegistrationInfo {
  createdAt: Timestamp;
}

export const EMPTY_REGISTRATION_INFO: RegistrationInfo = {
  sponsorId: "",
  candidateId: "",
  campaignId: "",
  browserAgent: "",
  ip4Address: "",
  ip6Address: "",
  deviceId: "",

  createdAt: new Date().toISOString(),
};
